import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService } from "./services/auth/auth.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { ApiService } from "./services/api/api.service";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BannerComponent } from "./components/banner/banner.component";
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CarouselModule } from "ngx-owl-carousel-o";
import { HotelService } from "./services/hotel/hotel.service";
import { PagesService } from "./services/pages/pages.service";
import { MembershipService } from "./services/membership/membership.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MainModule } from "../modules/main/main.module";
import { SignupComponent } from "./components/signup/signup.component";
import { SigninComponent } from "./components/signin/signin.component";
import { MatchPasswordDirective } from "./directives/match-password.directive";
import { NotFoundComponent } from "./components/not-found/not-found.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    SignupComponent,
    SigninComponent,
    MatchPasswordDirective,
    NotFoundComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthService,
    ApiService,
    HotelService,
    PagesService,
    MembershipService,
  ],
  imports: [
    NgbModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    CarouselModule,
    MatIconModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatNativeDateModule,
    FormsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    SigninComponent,
    SignupComponent,
    MatchPasswordDirective,
    NotFoundComponent

  ],
  entryComponents: [SigninComponent, SignupComponent],
})
export class CoreModule {}
