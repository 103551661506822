import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private api: ApiService) {}
  getNotification() {
    return this.api.get("api/notification/getByid");
  }
  getCount() {
    return this.api.get("api/notification/getCount");
  }
  updateNotification(id) {
    
    return this.api.put("api/notification/update/"+id,{status:true});
  }
  getDocuments(user_id): any {
    return this.api.get("api/document/getByUserId?_id="+user_id);
  }
}
