import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth/auth.service";
import { SignupComponent } from "../signup/signup.component";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"],
})
export class SigninComponent implements OnInit {
  @ViewChild("modal") private modalContent: TemplateRef<SigninComponent>;
  private modalRef: NgbModalRef;
  loading: any = false;
  signup: boolean = false;
  showModal1 = false;
  model = {
    email: "",
    password: "",
  };
  @Input() fromParent;
  showErrorMessage: string = "";
  user: any;
  serverErrorMessages: any;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    public authService: AuthService
  ) {
    // this.authService.getUser().subscribe((res) => {
    //   console.log("users", res);
    // });
  }

  ngOnInit() {
    console.log("parent",this.fromParent);
  }
  openModal() {
    this.closeModal("dismiss");
    const modalRef = this.modalService.open(SignupComponent, {
      windowClass: "myCustomModalClass",
    });
    modalRef.componentInstance.fromParent =this.fromParent;
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }

  onSubmit(form: NgForm, sendData) {
    console.log(form.value, this.fromParent);
    this.authService
      .login({ email: form.value.email, password: form.value.password })
      .subscribe(
        (res) => {
          console.log("ressss", res);
          this.authService.setToken(res["token"]);
          if (res.token) {
            this.closeModal("dismiss");
            if(this.fromParent.prop1==='home')
              window.location.href = '/';
            else if(this.fromParent.prop1==='about')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='hotel-details')
              window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='hotels')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='resorts')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='houseboats')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='properties')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
            else if(this.fromParent.prop1==='membership')
            window.location.href = '/membership-details/'+this.fromParent.prop2;
            else
            this.router.navigate(["/dashboard"]);
          } else this.showErrorMessage = res.message;
        },
        (err) => {
          this.showErrorMessage = err.error.message;
        }
      );
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
