import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class HotelService {
  constructor(private api: ApiService) {}
  getHotels(): any {
    return this.api.get("api/hotel/");
  }
  getAllHotels(): any {
    return this.api.get("api/hotel/all");
  }
  getRoomType():any{
    return this.api.get("api/roomtype/");

  }
  hotels=[];
  all_hotels =[];
  getHotelList() {
    return this.hotels.length ? of(this.hotels)
      : this.api.get('api/hotel/').pipe(
        map((data) => {
          console.log("data",data)
          this.hotels = data['hotels'];
          return this.hotels;
        })
      )
  }
  getAllHotelList() {
    return this.all_hotels.length ? of(this.all_hotels)
      : this.api.get('api/hotel/all').pipe(
        map((data) => {
          console.log("data",data)
          this.all_hotels = data['hotels'];
          return this.all_hotels;
        })
      )
  }
  getHotelByName(name): any {
    return this.api.get("api/hotel/getByName?name="+name);
  }
  getHotelById(id): any {
    return this.api.get("api/hotel/getByid?_id=" + id);
  }
  getRoomsByHotel(id):any{
    return this.api.get("api/room/getByHotelId?_id=" + id);
  }
  getNonFeaturedHotels(): any {
    return this.api.get("api/resort/getNonFeatured");
  }
  getFeaturedHotels(): any {
    return this.api.get("api/hotel/getFeatured");
  }
  getFilteredHotels(body):any{
    return this.api.post("api/hotel/getFiltered",body);

  }
  getFilteredAllHotels(body):any{
    return this.api.post("api/hotel/getFilteredAll",body);

  }
  getPopularHotels():any{
    return this.api.get("api/hotel/getPopular");

  }
  getPropertyByLocation(body):any{
    return this.api.post("api/hotel/getByLocation",body);
  }
  getAllByLocation(body):any{
    return this.api.post("api/hotel/getAllByLocation",body);
  }
  getImagesByHotelId(id) {
    return this.api.get("api/hotelimage/getByHotelId?_id=" + id
    );
  }
  getCancellationPolicy(id) {
    return this.api.get("api/hotel/getPolicy?_id=" + id
    );
  }
  getNotesByHotelId(id) {
    return this.api.get("api/hotel/getNotesByHotelId?_id=" + id
    );
  }
  getCities():any{
    // return this.api.get("api/location/getByParent?state=Kerala");
    return this.api.get("api/hotel/getDestinations");
  }
  getPropertyDetails():any{
    return this.api.get("api/hotel/getPropertyDetails");
  }
}
