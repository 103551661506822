import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  @Output() refreshUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private api: ApiService, private http: HttpClient) {}

  // noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  register(user) {
    const data = this.api.post("api/user/register", user);
    return data;
  }

  getUser() {
    const data = this.api.get("api/user/userProfile");
    return data;
  }
  changePic(file){
    return this.api.postFile("api/user/changePic",file);

  }
  updateUser(user){
    const data = this.api.post("api/user/updateUser", user);
    return data;
  }

  login(authCredentials) {
    return this.api.post("api/user/authenticate", authCredentials);
  }
  resetPassword(body) {
    return this.api.post("api/user/reset", body);
  }
  setToken(token) {
    console.log("token", token);
    localStorage.setItem("JWT_TOKEN", token);
    this.refreshUser.emit(token);
  }

  getToken() {
    return localStorage.getItem("JWT_TOKEN");
  }
  getRefreshToken() {
    return localStorage.getItem("REFRESH_TOKEN");
  }

  deleteToken() {
    localStorage.removeItem("JWT_TOKEN");
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split(".")[1]);
      return JSON.parse(userPayload);
    } else return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload) return userPayload.exp > Date.now() / 1000;
    else return false;
  }
  logout() {
    localStorage.removeItem("JWT_TOKEN");

    return this.api.get("api/user/logout", {
      refreshToken: this.getRefreshToken(),
    });
  }
}
