import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { PagesService } from "../../services/pages/pages.service";
import { ApiService } from "src/app/core/services/api/api.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {

partners:any=[];
year;
  constructor(public router: Router, public pagesService:PagesService, public apiService:ApiService) {
    
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.pagesService.getPartners().subscribe(
      (resp: any) => {
        console.log("response->shorttt",resp);
        this.partners= resp.partners
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  partnerOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 3,
      },
      740: {
        items: 5,
      },
      940: {
        items: 5,
        loop: true,
      },
    },
    nav: false,
  };
  goHome = () => this.router.navigate(["/"]);
  goAbout = () => this.router.navigate(["/about"]);
  goHotels = () => this.router.navigate(["/hotels"]);
  goResorts = () => this.router.navigate(["/resorts"]);
  goMembership = () => this.router.navigate(["/membership"]);
  partnerWithUs= () => this.router.navigate(["/partner-with-us"]);
  contactUs= () => this.router.navigate(["/contact-us"]);
  terms= () => this.router.navigate(["/terms-conditions"]);

}
