import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SigninComponent } from 'src/app/core/components/signin/signin.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';
declare var bootbox;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild("modal") private modalContent: TemplateRef<SigninComponent>;
  private modalRef: NgbModalRef;
  loading: any = false;
  signup: boolean = false;
  model = {
    email: "",
    password: "",
  };
  @Input() fromParent;
  showErrorMessage: string = "";
  user: any;
  serverErrorMessages: any;

  @Input('show') show: boolean;
  banner = "login"
  message ='';
  
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    public authService: AuthService
  ) {

  }

  ngOnInit() {
    console.log("parent",this.fromParent);
  }



  onSubmit(form:NgForm) {
    console.log("form",form.value)
    this.authService.resetPassword({     
      old_password: form.value.current_password,
      password:form.value.new_password
    }).subscribe(
      res => {
        if(res.status){
          console.log("change",res)
          var dialog = bootbox.dialog({
            message: "Password changed Successfully!!",
            closeButton: true
          });
          this.closeModal('dismiss');
          this.router.navigateByUrl('/')
        }else{
          this.message=res.message;
        }
  
       // this.router.navigate(['/dashboard']);
      },
      err => {
        console.log("err", err)
        if (err.status === 400) {
          form.resetForm()
          this.showErrorMessage = "Incorrect Password";
        }

      }
    );
  }
  showForm() {
    this.show = true;

  }
  //Bootstrap Modal Close event
  hide(form) {
    this.show = false;
  form.resetForm();
    console.log("show", this.show);
    
  }

  



  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
