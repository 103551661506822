import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { SigninComponent } from "../signin/signin.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  serverErrorMessages: string;
  @ViewChild("modal") private modalContent: TemplateRef<SignupComponent>;
  @Input() fromParent;

  private modalRef: NgbModalRef;
  loading: any = false;
  signup: boolean = false;
  showModal1 = false;
  model = {
    first_name: "",
    last_name: "",
    confirmPassword: "",
    dob:"",
    phone:"",
    email: "",
    password: "",
  };
  showErrorMessage: any;

  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private datePipe: DatePipe,

    private modalService: NgbModal
  ) {}

  ngOnInit() {
    console.log("parent",this.fromParent);
  }
  openModal() {
    this.closeModal("dismiss");
    const modalRef = this.modalService.open(SigninComponent, {
      windowClass: "myCustomModalClass",
    });
    modalRef.componentInstance.fromParent = "reg";
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  getTomorrow() {
    let d = new Date();
    d.setDate(d.getDate() + 1);
    return this.datePipe.transform(d, "yyyy-MM-dd");
  }
  onSubmit(form: NgForm, sendData) {
    console.log(form.value);

    this.authService
      .register({
        email: form.value.email,
        password: form.value.password,
        phone:form.value.phone,
        dob:form.value.dob,

        first_name: form.value.first_name,
        last_name: form.value.last_name,
      })
      .subscribe(
        (res) => {
          console.log("ressss", res);
          this.authService.setToken(res["token"]);
          this.closeModal("dismiss");
          if (res.token) {
            this.closeModal("dismiss");
            if(this.fromParent.prop1==='home')
            window.location.href = '/';
            else if(this.fromParent.prop1==='about')
            window.location.href = '/about';
            else if(this.fromParent.prop1==='hotel-details')
            window.location.href = '/hotel-details/'+this.fromParent.prop2;
          else if(this.fromParent.prop1==='hotels')
          window.location.href = '/hotels';
          else if(this.fromParent.prop1==='resorts')
          window.location.href = '/resorts';
          else if(this.fromParent.prop1==='membership')
          window.location.href = '/membership-details/'+this.fromParent.prop2;
            else
            this.router.navigate(["/dashboard"]);
          } else this.showErrorMessage = res.message;
        },
        (err) => {
          console.log("error",err.error)
          this.showErrorMessage = err.error;
        }
      );
  }


}
