import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SigninComponent } from "src/app/core/components/signin/signin.component";
import { ChangePasswordComponent } from "src/app/modules/main/components/change-password/change-password.component";
import { ApiService } from "../../services/api/api.service";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationService } from "../../services/notification/notification.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input("page") page: string;
  // @HostListener('scroll', ['$event'])

  sticky=false;
  show: any = false;
  user: any;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private apiService: ApiService,
    private notifyService: NotificationService
  ) {
  
  }
  scroll= (event: any): void => {
    
    var element = document.querySelector('#main-container');
    if(element){
      var position = element.getBoundingClientRect();
      // checking whether fully visible
      if(position.top >= 100 && position.bottom <= window.innerHeight) {
        this.sticky=true;
        console.log("1")
      }
    
      // checking for partial visibility
      else if(position.top < window.innerHeight && position.bottom >= 0) {
        this.sticky=true;
        console.log("2")

      }
      // else{
      //   this.sticky=false;
      //   console.log("3")

    
      // } 
   }
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true); //third parameter

    if (this.authService.isLoggedIn()) {
      console.log("enter");
      this.authService.getUser().subscribe((resp) => {
        console.log("user", resp);
        this.user = resp.user;
        if (this.user) {
          if (this.user.profile_pic)
            this.user.profile_pic =
              this.apiService.url + "/static/uploads/" + this.user.profile_pic;
          console.log("user", this.user);
        
        }
      });
    }
  }
  goHome = () => this.router.navigate(["/"]);
  goAbout = () => this.router.navigate(["/about"]);
  goHotels = () => this.router.navigate(["/hotels"]);
  goResorts = () => this.router.navigate(["/resorts"]);
  goHouseBoats = () => this.router.navigate(["/houseboats"]);

  goMembership = () => this.router.navigate(["/membership"]);
  partnerWithUs = () => this.router.navigate(["/partner-with-us"]);
  contactUs = () => this.router.navigate(["/contact-us"]);

  openModal() {
    console.log("enter");
    const modalRef = this.modalService.open(SigninComponent, {
      // scrollable: true,
      windowClass: "myModalClass",
    });

    let data = {};

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }
  openChangePassword() {
    console.log("enter");
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      // scrollable: true,
      windowClass: "myModalClass",
    });

    let data = {
      // id: this.id,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }
  logout() {
    this.authService.logout().subscribe(
      (resp: any) => {
        console.log("response->shorttt", resp);
        this.show = false;
        // this.router.navigateByUrl('/');
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  showProfile() {
    this.show = !this.show;
  }

  goDashboard = () => this.router.navigate(["/dashboard"]);
}
