import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  constructor(private api: ApiService) {}
  getBanner(name): any {
    return this.api.get("api/banner/getByName?name=" + name);
  }
  getCms(slug): any {
    return this.api.get("api/cms/getBySlug?slug=" + slug);
  }
  getPartners() {
    return this.api.get("api/partner/");
  }
  getMembershipOffers() {
    return this.api.get("api/offer/");
  }
  getPartnerDetails() {
    return this.api.get("api/partner-detail/");
  }
  getBecomeMemberSteps() {
    return this.api.get("api/become-member/");
  }
  getFaqs() {
    return this.api.get("api/faq/");
  }
  contactUs(data) {
    return this.api.post("api/contact/",data);
  }
  nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    else return num;
  }
}
