import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable, of } from 'rxjs';
import { tap, startWith, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MembershipService {
  constructor(private api: ApiService, private http: HttpClient,) {}
  getMembership(): any {
    return this.api.get("api/membership/");
  }
  getMembershipById(id): any {
    return this.api.get("api/membership/getByid?_id=" + id);
  }
  getPlanByUser(user_id) {
    return this.api.get("api/member/getPlanByUser?user_id=" + user_id);
  }
  register(body): any {
    return this.api.post("api/member/register", body);
  }
  // getCountries(): any {
  //   return this.api.get("api/member/getCountries");
  // }
  getCities(body): any {
    return this.api.post("api/member/getCities", body);
  }
  
  cities = [];
  countries = [];

  getCountries() {
    return this.countries.length ? of(this.countries)
      : this.api.get('api/member/getCountries').pipe(
        map((data) => {
          console.log("data",data)
          this.countries = data['countries'];
          return this.countries;
        })
      )
  }

  getData(body) {
    return  this.api.post('api/member/getCities',body).pipe(
        map((data) => {
          console.log("data",data)
          this.cities = data['cities'];
          return this.cities;
        })
      )
  }
  bookNow(body): any {
    return this.api.post("api/user/bookNow", body);
  }
  checkhotel(body): any {
    return this.api.post("api/hotel/checkhotel", body);
  }
  getReviews() {
    return this.api.get("api/review");
  }
}
