import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { PagesService } from "../../services/pages/pages.service";

@Component({
	selector: "app-banner",
	templateUrl: "./banner.component.html",
	styleUrls: ["./banner.component.css"],
})
export class BannerComponent implements OnInit {
	@Input("page") page: string;
	banner: any;
	constructor(private pagesService: PagesService, private datePipe: DatePipe, public apiService:ApiService ) {
		console.log("page", this.page);
	}

	ngOnInit() {
		this.pagesService.getBanner(this.page).subscribe(
			(resp: any) => {
				console.log("response->shorttt", resp);
				this.banner = resp.banner;
				if(resp.banner.cover_image!==null) this.banner.cover_image = this.apiService.url+'/static/uploads/'+this.banner.cover_image
			},
			(err: any) => {
				console.log(err);
			}
		);
	}
	getTomorrow() {
		let d = new Date();
		d.setDate(d.getDate() + 1);
		return this.datePipe.transform(d, "yyyy-MM-dd");
	}
}
